<template>
  <div
    v-if="status"
    class="blur"
    id="common_blur"
    @click="$emit('close')"
  ></div>
</template>

<script>
export default {
  name: "viewBlur",
  props: {
    status: Boolean,
  },
  watch: {
    status() {
      if (this.status) {
        //document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else {
        //document.getElementsByTagName("body")[0].style.overflow = "auto";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.blur {
  width: 100%;
  height: 100%;
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.6;
  transition: 0.2s;
}
</style>
