import Blur from "../utilComponents/blur.vue";

export default {
  components: {
    Blur: Blur,
  },
  data() {
    return {
      status: false,
    };
  },
  methods: {
    displayBlur() {
      this.status = true;
    },
    noneBlur() {
      this.status = false;
    },
  },
};
