<template>
  <el-container style="height: 100vh" id="sidebar">
    <div class="side_container" style="width: 250px">
      <img src="@/assets/img/common/logo01.png" alt="" class="logo" />
      <div
        style="
          color: #fff;
          padding: 5px;
          border-radius: 5px;
          margin-top: 0;
          margin-bottom: 10px;
        "
        :style="
          profile.type == 1
            ? 'background-color: #20b266;'
            : 'background-color:#1f3f83'
        "
      >
        {{ profile.name }}
      </div>
      <p>案件</p>
      <div class="wrap" v-if="profile">
        <ul class="first" v-if="!profile.type">
          <li :class="$route.path == '/request' ? 'current_page' : ''">
            依頼一覧
            <router-link to="/request"></router-link>
          </li>
          <li
            :class="
              $route.path == '/request/mysuggestion' ? 'current_page' : ''
            "
          >
            提案中の依頼一覧<router-link
              to="/request/mysuggestion"
            ></router-link>
          </li>
          <li :class="$route.path == '/task' ? 'current_page' : ''">
            タスク<router-link to="/task"></router-link>
          </li>
          <li :class="$route.path == '/company' ? 'current_page' : ''">
            対応中企業一覧<router-link to="/company"></router-link>
          </li>
          <li :class="$route.path == '/review' ? 'current_page' : ''">
            レビュー <router-link to="/review"></router-link>
          </li>

          <li :class="$route.path == '/notice' ? 'current_page' : ''">
            通知
            <router-link to="/notice"></router-link>
            <el-badge
              :value="unread"
              class="item"
              type="primary"
              v-if="unread != 0"
            >
            </el-badge>
          </li>
          <li :class="$route.path == '/substitute' ? 'current_page' : ''">
            代理登録
            <router-link to="/substitute"></router-link>
          </li>
        </ul>
        <ul class="first" v-else>
          <li :class="$route.path == '/request' ? 'current_page' : ''">
            依頼一覧
            <router-link to="/request"></router-link>
          </li>
          <li :class="$route.path == '/request/create' ? 'current_page' : ''">
            依頼新規作成
            <router-link to="/request/create"></router-link>
          </li>
          <li :class="$route.path == '/task' ? 'current_page' : ''">
            タスク<router-link to="/task"></router-link>
          </li>
          <li :class="$route.path == '/counsellor' ? 'current_page' : ''">
            依頼中税理士の一覧<router-link to="/counsellor"></router-link>
          </li>
          <li :class="$route.path == '/drive' ? 'current_page' : ''">
            ファイル一覧 <router-link to="/drive"></router-link>
          </li>
          <li :class="$route.path == '/account' ? 'current_page' : ''">
            共有リンク <router-link to="/account"></router-link>
          </li>
          <li :class="$route.path == '/authorise' ? 'current_page' : ''">
            ファイル閲覧権限設定
            <router-link to="/authorise"></router-link>
          </li>
          <li :class="$route.path == '/notice' ? 'current_page' : ''">
            通知 <router-link to="/notice"></router-link>
            <el-badge
              :value="unread"
              class="item"
              type="primary"
              v-if="unread != 0"
            >
            </el-badge>
          </li>
        </ul>
        <ul class="second">
          <li @click="logout" style="cursor: pointer">ログアウト</li>
          <li :class="$route.path == '/contact' ? 'current_page' : ''">
            お問い合わせ<router-link to="/contact"></router-link>
          </li>
          <template v-if="profile">
            <li
              v-if="profile.type == 1"
              :class="
                $route.path == '/company/' + profile.id ? 'current_page' : ''
              "
            >
              <router-link :to="'/company/' + profile.id"></router-link>
              会員情報
            </li>
            <li
              v-else
              :class="
                $route.path == '/counsellor/' + profile.id ? 'current_page' : ''
              "
            >
              <router-link :to="'/counsellor/' + profile.id"></router-link>
              会員情報
            </li>
          </template>

          <li :class="$route.path == '/mypage/edit' ? 'current_page' : ''">
            <router-link to="/mypage/edit"></router-link>
            会員情報の編集
          </li>
        </ul>
      </div>
      <!-- <div style="color:gray;margin-top:20px;">
                {{profile.name}}
            </div> -->
    </div>
  </el-container>
</template>

<script>
export default {
  components: {},
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    unread() {
      return this.$store.getters.unread;
    },
  },
  created() {
    let vm = this;
    this.intervalId = setInterval(function () {
      vm.$store.dispatch("user/getUnread");
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss">
$menuText: #606060;
$menuActiveText: #409eff;
$subMenuActiveText: #f4f4f5;

$menuBg: #ffffff;
$menuHover: #263445;

$subMenuBg: #1f2d3d;
$subMenuHover: #001528;

$sideBarWidth: 210px;

.side_container {
  .el-badge__content {
    padding: 0 !important;
    width: 24px !important;
    height: 24px !important;
    text-align: center;
    line-height: 24px;
    border-radius: 90px;
  }
}

.el-container {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transition: width 0.28s;
  background-color: $menuBg;
  height: 100%;
  position: fixed;
  font-size: 18px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 35;
  overflow: hidden;

  .side_container {
    padding: 30px;
    width: 250px;
    display: flex;
    flex-direction: column;

    .logo {
      width: 100%;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: #999999;
    }

    .wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .current_page {
        color: #409eff;
      }

      li {
        position: relative;
        font-size: 17px;

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .first {
        padding: 10px;

        li {
          line-height: 2.5;
        }
      }

      .second {
        li {
          line-height: 2.5;
        }

        img {
          margin-top: 10px;
          width: 30px;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.el-menu--collapse .el-menu .el-submenu {
  min-width: $sideBarWidth !important;
}

// mobile responsive
.mobile {
  .main-container {
    margin-left: 0px;
  }

  .sidebar-container {
    transition: transform 0.28s;
    width: $sideBarWidth !important;
  }

  &.hideSidebar {
    .sidebar-container {
      pointer-events: none;
      transition-duration: 0.3s;
      transform: translate3d(-$sideBarWidth, 0, 0);
    }
  }
}

.withoutAnimation {
  .main-container,
  .sidebar-container {
    transition: none;
  }
}

// when menu collapsed
.el-menu--vertical {
  & > .el-menu {
    .svg-icon {
      margin-right: 16px;
    }

    .sub-el-icon {
      margin-right: 12px;
      margin-left: -2px;
    }
  }

  .nest-menu .el-submenu > .el-submenu__title,
  .el-menu-item {
    &:hover {
      // you can use $subMenuHover
      background-color: $menuHover !important;
    }
  }

  // the scroll bar appears when the subMenu is too long
  > .el-menu--popup {
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
</style>
