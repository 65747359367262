import Cookies from "js-cookie";

const TokenKey = "mieta";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getOwnerToken(token) {
  return Cookies.set(TokenKey + "_o", token);
}

export function setOwnerToken(token) {
  return Cookies.set(TokenKey+'_o', token);
}


export function removeOwnerToken(token) {
  return Cookies.set(TokenKey + "_o", token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
