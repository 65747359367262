/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getToken } from "@/utils/auth";
import "./assets/style/global.scss";
import "@/assets/tailwind.css";
//通知コンポーネント
import Notifications from "vue-notification";
import ElTableInfiniteScroll from "el-table-infinite-scroll";
Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);
Vue.use(Notifications);
//通知コンポーネント End

//メディアクエリの定義とインポート
import VueMq from "vue-mq";
Vue.use(VueMq, {
    breakpoints: {
        mobile: 450,
        tablet: 900,
        laptop: 1200,
        desktop: Infinity,
    },
});
//メディアクエリの定義とインポート End

//ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ja"; // lang i18n
Vue.use(ElementUI, { locale });
//ElementUI End

// バリデーションパッケージのインストール npm install vee-validate@3 --save
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
for (let rule in rules) {
    extend(rule, rules[rule]);
}
extend("post", {
    message: "000-0000の形式でご入力ください。",
    validate(value) {
        var pattern = /^[0-9]{3}[-][0-9]{4}$/;
        return pattern.test(value);
    },
});
extend("phone", {
    message: "電話番号の形式でご入力ください。",
    validate(value) {
        var pattern = /^0\d{9,10}$/;
        return pattern.test(value);
    },
});
extend("kana", {
    message: "カタカナでご入力ください。",
    validate(value) {
        var pattern = /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/;
        return pattern.test(value);
    },
});
extend("half_kana", {
    message: "半角ｶﾅでご入力ください。",
    validate(value) {
        var pattern = /^[ｧ-ﾝﾞﾟ\()-./0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/;
        return pattern.test(value);
    },
});
extend("url", {
    message: "URLを入力してください",
    validate(value) {
        if (value.indexOf("http") === 0) {
            return true;
        }
    },
});
extend("numeric", {
    message: "半角数字のみ使用できます。",
});
extend("password", {
    message: "パスワードは半角英数字混同でなければいけません。",
    validate: (value) => {
        if (!value.match(/\d/)) return false;
        if (!value.match(/[a-z]/)) return false;
        return true;
    },
});
localize("ja", ja);
localize({ ja });
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// バリデーションパッケージ End

Vue.config.productionTip = false;

if (getToken()) {
    store.dispatch("user/getInfo");
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
