<template>
    <div id="main" :class="$mq != 'desktop' ? 'only_pc' : ''">
        <Sidebar v-if="$mq == 'desktop'" />
        <main v-if="$mq == 'desktop'">
            <router-view v-if="profile"></router-view>
            <!-- <router-view></router-view> -->
        </main>
        <p id="caution_msg" v-else>
            以下の環境でご利用くださいませ。<br /><br />
            ・10インチ以上のWindowsOS, MacOS搭載デバイス<br />
            ・Google Chrome, Microsoft Edge,FireFox
        </p>
    </div>
</template>
<script>
import Sidebar from "./sidebar/index.vue";
import Mixin from "../mixins/globalMethods.js";
// import request from "@/utils/request";
export default {
    name: "Main",
    mixins: [Mixin],
    components: {
        Sidebar,
    },
    data() {
        return {};
    },
    methods: {
        init() {},
        fixedHeader() {
            return this.$store.state.settings.fixedHeader;
        },
    },
    created() {
        this.init();
    },
    computed: {
        profile() {
            return this.$store.getters.profile;
        },
    },
};
</script>
<style lang="scss" scoped>
main {
    margin-left: 250px;
    padding: 40px;
    background-color: #f9f9f9;
    min-height: 100vh;
}
#main {
    min-width: 1440px;
}
#app {
    margin: 0 auto;
}
</style>
<style lang="scss">
.el-breadcrumb {
    .el-breadcrumb__item {
        &:last-child {
            .el-breadcrumb__inner {
                border: none;
                color: #2c2c2c;
            }
        }
        .el-breadcrumb__inner {
            color: #0268cc;
            border-bottom: 1px solid #0268cc;
        }
    }
}
.only_pc {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 375px !important;
    width: 100%;
    #caution_msg {
        font-size: 20px;
        font-weight: 700;
        padding: 10px;
    }
}
.header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    p {
        line-height: 1;
        font-size: 36px;
        width: 300px;
        position: relative;
        margin-right: 20px;
        &::after {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #d8d8d8;
        }
    }
}
</style>
