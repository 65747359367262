import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../layouts/main.vue";
import UnLogin from "../layouts/unLogin.vue";
import { getToken } from "@/utils/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainRoute",
    component: UnLogin,
    children: [
      {
        path: "/",
        name: "home",
        index: 1,
        component: () => import("@/views/TopPage"),
      },
      {
        path: "/404",
        component: () => import("@/views/404"),
        hidden: true,
      },
      {
        path: "/guest/contact",
        component: () => import("@/views/Contact/Guest"),
        hidden: true,
      },
      {
        path: "/login",
        name: "loginPage",
        index: 2,
        component: () => import("@/views/Auth/Login"),
        beforeEnter(to, from, next) {
          if (getToken()) {
            next("/request");
          } else {
            next(next);
          }
        },
      },
      {
        path: "/type",
        name: "type",
        component: () => import("@/views/Auth/TypeSelect"),
      },
      {
        path: "/forget-password",
        name: "passwordReset",
        index: 4,
        component: () => import("@/views/Auth/PasswordReset"),
      },
      {
        path: "/password-reset",
        name: "passwordResetForm",
        index: 5,
        component: () => import("@/views/Auth/PasswordResetForm"),
      },
      {
        path: "/sendmail/:type",
        name: "sendmail",
        component: () => import("@/views/Auth/Register"),
      },
      {
        path: "/register/:token",
        name: "register",
        component: () => import("@/views/Auth/RegisterForm"),
      },
      {
        //メールアドレス変更完了画面
        path: "/complete-email-change/:token",
        name: "completeEmailChange",
        index: 3,
        component: () => import("@/views/Auth/CompleteEmailChange"),
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("@/views/Law/PrivacyPolicy"),
      },
      {
        path: "/counsellor-terms",
        name: "CounsellorTerms",
        component: () => import("@/views/Law/CounsellorTerm"),
      },
      {
        path: "/company-terms",
        name: "CompanyTerms",
        component: () => import("@/views/Law/CompanyTerm"),
      },
      {
        path: "/certification",
        name: "Certification",
        component: () => import("@/views/Auth/Certification"),
      },
      {
        path: "/sub_pass_reset",
        name: "sub_pass_reset",
        component: () => import("@/views/Auth/SubPasswordResetForm"),
      },
    ],
  },
  {
    path: "/app",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/mypage",
        name: "Mypage",
        component: () => import("@/views/MyPage/TopPage"),
      },
      {
        path: "/mypage/edit",
        name: "Edit",
        component: () => import("@/views/MyPage/EditProfile"),
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("@/views/Account/Index"),
      },
      {
        path: "/account/:id",
        name: "AccountCompanyIndex",
        component: () => import("@/views/Account/Company"),
      },
      {
        path: "/counsellor",
        name: "CounsellorIndex",
        component: () => import("@/views/Counsellor/Index"),
      },
      {
        path: "/counsellor/:id",
        name: "CounsellorDetail",
        component: () => import("@/views/Counsellor/Detail"),
      },

      {
        path: "/request",
        name: "RequestIndex",
        component: () => import("@/views/Request/Index"),
      },
      {
        path: "/request/mysuggestion",
        name: "RequestSuggestion",
        component: () => import("@/views/Request/Suggestion"),
      },
      {
        path: "/request/create",
        name: "RequestCreate",
        component: () => import("@/views/Request/Create"),
      },
      {
        path: "/request/:id",
        name: "RequestDetail",
        component: () => import("@/views/Request/Detail"),
      },
      {
        path: "/task",
        name: "TaskIndex",
        component: () => import("@/views/Task/Index"),
      },
      {
        path: "/task/:id",
        name: "TaskDetail",
        component: () => import("@/views/Task/Detail"),
      },
      {
        path: "/review",
        name: "ReviewIndex",
        component: () => import("@/views/Review/Index"),
      },
      {
        path: "/company",
        name: "CompanyIndex",
        component: () => import("@/views/Company/Index"),
      },
      {
        path: "/company/:id",
        name: "CompanyDetail",
        component: () => import("@/views/Company/Detail"),
      },
      {
        path: "/drive",
        name: "DriveIndex",
        component: () => import("@/views/Drive/Index"),
      },
      {
        path: "/guest/drive",
        name: "GuestDriveIndex",
        component: () => import("@/views/Drive/Guest"),
      },
      {
        path: "/notice",
        name: "NoticeIndex",
        component: () => import("@/views/Notice/Index"),
      },
      {
        path: "/contact",
        name: "ContactIndex",
        component: () => import("@/views/Contact/Index"),
      },
      {
        path: "/substitute",
        name: "RequestIndex",
        component: () => import("@/views/Substitute/Index"),
      },
      {
        path: "/substitute/register",
        name: "RequestIndex",
        component: () => import("@/views/Substitute/register"),
      },
      {
        path: "/authorise",
        name: "AuthosriseIndex",
        component: () => import("@/views/Authorise/Index"),
      },
    ],
    beforeEnter(to, from, next) {
      if (getToken()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

export default router;
