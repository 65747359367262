<template>
  <div id="unlogin">
    <header>
      <router-link to="/">
        <img src="@/assets/img/common/logo_pc.png" alt="" />
      </router-link>

      <div class="right_wrap pc">
        <router-link to="/login"> ログイン </router-link>
        <router-link
          :to="{
            name: 'home',
            hash: '#flow',
          }"
        >
          事業者様の利用方法
        </router-link>
        <router-link
          :to="{
            name: 'home',
            hash: '#c_flow',
          }"
        >
          専門家様の利用方法
        </router-link>
        <router-link to="/guest/contact"> お問合せ </router-link>
        <router-link to="/type" class="button"> 会員登録 </router-link>
      </div>
    </header>
    <ul class="humb_btn" @click="flag ? (flag = false) : (flag = true)">
      <li v-if="!flag">
        <span class="material-symbols-outlined"> menu </span>
      </li>
      <li v-else>
        <span class="material-symbols-outlined"> close </span>
      </li>
    </ul>
    <div class="hamburger" v-if="flag" :style="flag ? 'right:0;' : ''">
      <ul>
        <li>
          ログイン
          <router-link to="/login" @click.native="flag = false"></router-link>
        </li>
        <li>
          利用方法
          <router-link
            @click.native="flag = false"
            :to="{
              name: 'home',
              hash: '#flow',
            }"
          >
          </router-link>
        </li>
        <li>
          お問合せ
          <router-link to="/guest/contact" @click.native="flag = false">
          </router-link>
        </li>
        <li>
          会員登録
          <router-link to="/type" class="button" @click.native="flag = false">
          </router-link>
        </li>
      </ul>
    </div>
    <main :class="$route.path != '/' ? 'main_layout' : ''">
      <router-view></router-view>
    </main>
    <footer id="footer">
      <div class="footer_container">
        <div class="left">
          <img src="@/assets/img/common/logo-w.png" alt="" />
          <p>
            運営会社：株式会社アカウンタックス<br />
            住所：東京都千代田区平河町２−１１−２ユースクエア平河町２階
            <br />
            Tel：<a href="tel:03-3237-1311">03-3237-1311</a>
          </p>
        </div>
        <div class="right">
          <ul>
            <li>事業者の方へ</li>
            <li>
              ログイン
              <router-link to="/login" @click="flag = false"></router-link>
            </li>
            <li>
              会員登録
              <router-link to="/sendmail/company"></router-link>
            </li>
          </ul>
          <ul>
            <li>専門家の方へ</li>

            <li>
              ログイン
              <router-link to="/login"></router-link>
            </li>
            <li>
              会員登録
              <router-link to="/sendmail/counsellor"></router-link>
            </li>
          </ul>
          <ul>
            <li>本サービスについて</li>
            <li>
              ご利用方法
              <router-link
                :to="{
                  name: 'home',
                  hash: '#flow',
                }"
              ></router-link>
            </li>
            <li>
              運営会社
              <a href="https://accountax.co.jp" target="_blank"></a>
            </li>
            <li>
              プライバシーポリシー
              <router-link to="/privacy-policy"></router-link>
            </li>
          </ul>
        </div>
      </div>
      <p>Copyright 2022 - a-Tax All right Recived</p>
    </footer>
  </div>
</template>
<script>
import Mixin from "../mixins/globalMethods.js";
export default {
  name: "Main-page",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      flag: false,
    };
  },
  methods: {
    init() {},
  },
  created() {
    this.init();
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.remainingNumber-wrap {
  //width: 30px;
  position: relative;

  .remainingNumber {
    //position: absolute;
    right: 0;
    font-size: 12px;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
  }
}
</style>
<style lang="scss" scoped>
#app {
  margin: 0 auto;
  background-color: #f1f7fe;
}

.pc {
  @media screen and (max-width: 1400px) {
    display: none !important;
  }
}

header {
  padding: 0 60px;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  width: 100%;
  position: fixed;
  z-index: 9999;
  color: #0f1f4d;
  align-items: center;
  @media screen and (max-width: 1400px) {
    padding: 0 20px;
  }
  img {
    width: 200px;
  }
  .right_wrap {
    display: flex;
    column-gap: 30px;
    align-items: center;
    a {
      display: block;
      font-size: 18px;
      font-weight: 500;
    }
    .button {
      background-color: #20b266;
      color: #ffffff;
      width: 170px;
      height: 55px;
      text-align: center;
      line-height: 55px;
      border-radius: 9px;
    }
  }
}

.main_layout {
  margin-top: 80px;
  background-color: #f1f7fe;
  padding: 80px 0 130px;
}

footer {
  color: #ffffff;
  background: #1f3f83;
  padding: 40px 320px 20px;
  font-weight: 300;
  @media screen and (max-width: 1400px) {
    padding: 40px 20px 20px;
  }
  > p {
    margin-top: 100px;
    font-size: 16;
    text-align: center;
  }
  .footer_container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1400px) {
      flex-wrap: wrap;

      row-gap: 20px;
    }
    .left {
      @media screen and (max-width: 1400px) {
        margin: 0 auto;
      }
      img {
        width: 320px;
        display: block;
        margin-bottom: 20px;
        @media screen and (max-width: 1400px) {
          width: 100%;
        }
        p {
        }
      }
    }
    .right {
      display: flex;
      column-gap: 50px;
      @media screen and (max-width: 1400px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
      ul {
        li {
          margin-bottom: 15px;
          position: relative;
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 18px;
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

#unlogin {
  .hamburger {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    background-color: #c1d7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    @media screen and (max-width: 1400px) {
      display: flex;
    }
    ul {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      li {
        position: relative;
        font-size: 20px;
        font-weight: 700;
        color: #1f3f83;
        a {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .humb_btn {
    position: fixed;
    z-index: 99999999;
    top: 25px;
    right: 20px;
    display: none;
    @media screen and (max-width: 1400px) {
      display: block;
    }
    li {
      span {
        font-size: 30px;
        color: #1f3f83;
      }
    }
  }
}
</style>
